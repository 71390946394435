import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HomeHeader, Banner, Social, ParallexHeader, Section } from "../utils/";
import homeBcg from "../utils/img/bcg/homeBcg.jpeg";
// import styled from "styled-components";
// import floatingBG from "../utils/img/floatingBG.svg";
import CardColorList from "../components/03-solutions/ColorCardList";
import WorkFlow from "../components/04-workflow/WorkFlow";
import ContactList from "../components/06-contact/ContactList";
// import CardScale from "../components/02-services/CardScale";
import CardScaleWhite from "../components/02-services/CardScaleWhite";
import FAQSection from "../components/02-services/FAQSection";
import { graphql, StaticQuery } from "gatsby";
// import { CardColor, BlogCard } from "../utils/CardsLibrary";
// import QuickInfo from "../components/HomePageCmp/QuickInfo";
import QuotesSlide from "../components/QuotesSlide";

const IndexPage = ({
  heroSlogan,
  heroTitle,
  heroSubtitle,
  heroIconTitles,
  heroDescription,
  serviceTitle,
  serviceSubtitle,
  fstPrlxTitle,
  flowTitle,
  flowSubTitle,
  flowDescription,
  socialTitle,
  socialSubTitle,
  solutionTitle,
  solutionSubTitle
}) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />

    {/* Start */}
    <StaticQuery
      query={HomeDataQUERY}
      render={data => {
        let homeData = data.homePageData.edges[0];
        const {
          hHeroSlogan,
          hHeroTitle,
          hHeroSubtitle,
          hHeroIconTitles,
          hServiceTitle,
          hFstPrlxTitle,
          hFlowTitle,
          hFlowSubTitle,
          hSocialTitle,
          hSocialSubTitle,
          hSolutionTitle,
          hHeroDescription,
          hServiceSubtitle,
          hFlowDescription,
          hSolutionSubTitle
        } = homeData.node;
        return (
          <div>
            <HomeHeader img={homeBcg}>
              <Banner
                slogan={hHeroSlogan || heroSlogan}
                title={hHeroTitle || heroTitle}
                subtitle={hHeroSubtitle || heroSubtitle}
                iconsArray={hHeroIconTitles || heroIconTitles}
                description={
                  hHeroDescription.hHeroDescription || heroDescription
                }
              />
            </HomeHeader>
            <CardScaleWhite
              title={hServiceTitle || serviceTitle}
              subtitle={hServiceSubtitle.hServiceSubtitle || serviceSubtitle}
            />
            <ParallexHeader>
              <Section>
                <QuotesSlide quoteTitle={hFstPrlxTitle || fstPrlxTitle} />
              </Section>
            </ParallexHeader>
            <WorkFlow
              title={hFlowTitle || flowTitle}
              message={hFlowSubTitle || flowSubTitle}
              description={hFlowDescription.hFlowDescription || flowDescription}
            />
            <Social
              title={hSocialTitle || socialTitle}
              subTitle={hSocialSubTitle || socialSubTitle}
            />
            <CardColorList
              title={hSolutionTitle || solutionTitle}
              message={hSolutionSubTitle.hSolutionSubTitle || solutionSubTitle}
            />
            <FAQSection />
            <ContactList />
          </div>
        );
      }}
    />
  </Layout>
);

const HomeDataQUERY = graphql`
  {
    homePageData: allContentfulHomePage {
      edges {
        node {
          hHeroSlogan
          hHeroTitle
          hHeroSubtitle
          hHeroIconTitles
          hHeroDescription {
            hHeroDescription
          }
          hServiceTitle
          hServiceSubtitle {
            hServiceSubtitle
          }
          hFstPrlxTitle
          hFlowTitle
          hFlowSubTitle
          hFlowDescription {
            hFlowDescription
          }
          hSocialTitle
          hSocialSubTitle
          hSolutionTitle
          hSolutionSubTitle {
            hSolutionSubTitle
          }
        }
      }
    }
  }
`;

IndexPage.defaultProps = {
  heroSlogan: "Leverage your business with the cutting-edge blockchain techs_",
  heroTitle: "XBOUND_",
  heroSubtitle: "BLOCKCHAIN CONSULTANCY SERVICES_",
  heroIconTitles: [
    "etherIcon",
    "hyperledgerIcon",
    "nodejsIcon",
    "solidityIcon",
    "reactIcon",
    "gatsbyIcon"
  ],
  heroDescription:
    "The massive opportunities and promises of blockchain technology are what we believe in and the results are what we deliver. With new use cases just keep coming in each and everyday. We provide an end-to-end blockchain development and auditing services that best suit your organization_",
  serviceTitle: "Software services_",
  serviceSubtitle:
    "Whether you want to integrate, migrate, digitally transform, or build your blockchain application from the ground up, we got you covered. We provide a full-stack web development on top of the blockchain. Together we'll drive your idea all the way up till production_",
  fstPrlxTitle: "Why you should consider Blockchain?_",
  flowTitle: "Our Development Process_",
  flowSubTitle:
    "Ensure team are always working on something important. Smaller chunks for bigger deliverables_",
  flowDescription:
    "To keep you involved during the whole delivery process. We leverage the Agile methodologies by breaking projects down into smaller, iterative deliveries each of will be managed by a scrum team and will be visible to the stakeholders through a shared Kanban_",
  hSocialTitle: "Social Media_",
  hSocialSubTitle: "Follow us on social media for new articles and offers_",
  solutionTitle: "Our solutions_",
  solutionSubTitle:
    "We use the cutting-edge technologies and stacks to ensure the uniqueness of your application. Our team conducts a separate analysis of the required technology stack for each and every application to guarantee the best features out of the box and to meet the customer's expectations_"
};

export default IndexPage;

import React from "react";
import HStepsBar from "./HStepsBar";
import { BannerButton, Title, Section } from "../../utils/";

import { Link } from "gatsby";
export default function WorkFlow({ title, message, description }) {
  return (
    <div>
      <Section>
        <Title message={message} title={title} />
        <HStepsBar />
        <p
          style={{
            padding: "0.7rem 1.5rem",
            textAlign: "center",
            lineHeight: "2rem"
          }}
        >
          {description}
        </p>
        <Link to="/solutions/">
          <BannerButton style={{ margin: "3rem auto" }}>Read more</BannerButton>
        </Link>
      </Section>
    </div>
  );
}

/************************
 * Responsive Cards.
 * 1.CardColor Class inculdes a small card that contains svg icon along with title. it has a nice color effect on hover and here we defined a state color for each card manually, although we can use the random method below to generate a random color for each card:
 * CardColor Source: https://codepen.io/jorgesanes10/pen/QdMEXr
 *
 * 2. BlogCards:
 * Source: https://codepen.io/ChynoDeluxe/pen/bdXeqQ?editors=1100
 *************************/
import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
/**  Solutions Icons  */
import {
  styles,
  etherIcon,
  reactIcon,
  reduxIcon,
  nodejsIcon,
  gatsbyIcon,
  graphqlIcon
} from "../../utils/";
// } from "../../utils/icon/sol_icons";

export default class ColorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // colorsArray: [
      //   "#00CED1",
      //   "#a1c8f9",
      //   "#ceb2fc",
      //   "#1873e3",
      //   "#72acf7",
      //   "#C0C0C0",
      //   "#dce9ff"
      // ],
      Links: [
        {
          id: 1,
          title: "Ethereum",
          description: "Lorem ipsum dolor sdsf df sdf sf ",
          color: "#CBD0EF",
          icon: <img src={etherIcon} alt="Blockchain services" />
        },
        {
          id: 2,
          title: "React",
          description: "Lorem ipsum dolor sdsf df sdf sf ",
          color: "#43C7E0",
          icon: <img src={reactIcon} alt="Blockchain services" />
        },
        {
          id: 3,
          title: "Redux",
          description: "Lorem ipsum dolor sdsf df sdf sf ",
          color: "#9871D5",
          icon: <img src={reduxIcon} alt="Blockchain services" />
        },
        {
          id: 4,
          title: "NodeJs",
          description: "Lorem ipsum dolor sdsf df sdf sf ",
          color: "#B3E87A",
          icon: <img src={nodejsIcon} alt="Blockchain services" />
        },
        {
          id: 5,
          title: "Gatsby",
          description: "Lorem ipsum dolor sdsf df sdf sf ",
          color: "#9A77BF",
          icon: <img src={gatsbyIcon} alt="Blockchain services" />
        },
        {
          id: 6,
          title: "Graphql",
          description: "Lorem ipsum dolor sdsf df sdf sf ",
          color: "#EE60C0",
          icon: <img src={graphqlIcon} alt="Blockchain services" />
        }
      ]
    };
  }

  render() {
    /** Generate a random Hexadecimal Color */
    const { Links } = this.state;
    // const HandleRandomColor = i => {
    //   let randomColor =
    //     colorsArray[Math.floor(Math.random() * colorsArray.length)];

    //   // var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    //   // console.log(randomColor);
    //   return randomColor;
    // };
    return (
      <CardsColorWrapper>
        {Links.map((item, index) => (
          <CardWrapp rColor={item.color} key={index}>
            <Link to="/solutions/" className="cust-card">
              <div className="overlay" />
              <div className="circle">{item.icon}</div>
              <p>{item.title}</p>
            </Link>
          </CardWrapp>
        ))}
      </CardsColorWrapper>
    );
  }
}

/************************************/
/** SlidesInfo Styled Component */
/************************************/
const CardsColorWrapper = styled.div`
  margin: 2rem 1rem;
  padding: 0rem 1%.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* Flex-wrap means that items will move onto a new row when the horizontal space runs out – instead of squishing all of the items onto one row */
  flex-wrap: wrap;
`;

/************************************/
/** CardWrapper Styled Component */
/************************************/
const CardWrapp = styled.div`
   --bg-color: ${props => props.rColor || "blue"};
   --bg-color-light: #fff;
   --text-color-hover: #fff;
   --box-shadow-color: var( --bg-color);

   /* Card Width 
   * 180x250 , 220x300
   */
   --card-width: 160px;
   --card-height: 230px;

/* Circle Width 
*  148x148, 
*/
   --circle-width: 128px;
   --circle-height: 128px;
   --circle-whitebg-width: 108px;
   --circle-whitebg-height: 108px;
 

/* Icon Size
*  82px, 76px 
*/
--icon-height:74px;

/* Icon background overlay color  Size
*  WxH: 114pxx114px,
*  TopxLeft: 70,50  50,30 
*/  
  --circle-bgOverlay-width:90px;
  --circle-bgOverlay-height:90px;
  --circle-bgOverlay-top:40px;
  --circle-bgOverlay-left:30px;
    


  .cust-card {
    margin:1.1rem 2rem;
    width: var( --card-width);
    height: var(--card-height);         
    background: ${styles.colors.mainWhite};
    border-top-right-radius: 15px;    
    /* border-radius:100px; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 14px 20px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease-out;
    text-decoration: none;
  }
  .cust-card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
      0 24px 36px var(--box-shadow-color);
  }
  .cust-card:hover .overlay {
    transform: scale(4) translateZ(0);
  }
  .cust-card:hover .circle {
    border-color: var(--bg-color-light);
    background: var(--bg-color);
  }
  .cust-card:hover .circle:after {
    background: var(--bg-color-light);
  }
  .cust-card:hover p {
    color: var(--text-color-hover);
  }
  .cust-card:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
      0 15px 24px var(--box-shadow-color);
  }
  .cust-card p {
    font-size: 1.42rem;
    letter-spacing:0.1rem;
    text-transform:capitalize;
    /* font-weight:bold; */
    color: var(--bg-color);
    /* color: rgb(145, 145, 145); */
    margin-top: 30px;
    z-index: 0;
    transition: color 0.3s ease-out;
  }
  .circle {
    width: var(--circle-width);
    height: var(--circle-height);
    border-radius: 50%;
    border: 2px solid var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    transition: all 0.3s ease-out;
  }
  .circle:after {
    content: "";
    width: var(--circle-whitebg-width);
    height: var(--circle-whitebg-height);
    display: block;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    top: 8px;
    left: 8px;
    transition: opacity 0.3s ease-out;
  }
  .circle img{   
    height: var(--icon-height);    
    width: auto;  
    z-index: 1;    
    transform: translateZ(0);
  }
  .overlay {
    width: var(--circle-bgOverlay-width);
    height: var(--circle-bgOverlay-height);
    position: absolute;    
    border-radius: 50%;
    background: var(--bg-color);
    top: var(--circle-bgOverlay-top);
    left: var(--circle-bgOverlay-left);
    z-index: 0;
    transition: transform 0.3s ease-out;
  }
  @media (min-width: 398px) {
    width: 50%;
  }
  @media  (min-width: 768px) {
    width: 33.33%;
  }
  @media (min-width:992px){   
  width: 20%;
  }
  
   @media (min-width:1200px){   
  width: 15%;
  }
   /*  
 .education {
    --bg-color: ${props => props.rColor || "red"};
    --bg-color-light: #ffeeba;
    --text-color-hover: #4c5656;
    --box-shadow-color: rgba(25%, 55%, 70%, 0.48);
  } */
 
`;

/**
 * Source: https://codepen.io/dylanmcleod/pen/merXXr
 * Scale card with blue background and white color and icons    click="window.location='#'"
 */
import React, { Component } from "react";
import styled from "styled-components";
// import floatingBG from "../../utils/img/floatingBG.svg";
import { StaticQuery, graphql } from "gatsby";
import { Title, Section, styles, BannerButton } from "../../utils";
import { Link } from "gatsby";
export default class CardScaleWhite extends Component {
  render() {
    const { title, subtitle } = this.props;
    return (
      <div>
        <Section>
          <Title message={subtitle} title={title} />
          {/* <div className="divider-line" /> */}
        </Section>
        <ScaleCardWrapper>
          <div id="btns">
            <StaticQuery
              query={SERVICEQUERY}
              render={data => {
                const serviceCard = data.servicequery.edges;
                return serviceCard.map(service => {
                  const { index, title, shortDescription } = service.node;
                  const imgsrc = require(`../../utils/icon/serv_icons/${
                    service.node.iconTitle
                  }.svg`);

                  /* const imgsrc = require(`../../utils/icon/serv_icons/${
                    service.node.iconTitle
                  }.svg`); */

                  return (
                    <div className="btn" key={index}>
                      <img className="icon" src={imgsrc} alt={title} />
                      <h2>{title}</h2>
                      <p>{shortDescription.shortDescription}</p>
                    </div>
                  );
                });
              }}
            />
          </div>
        </ScaleCardWrapper>
        <Link to="/services/">
          <BannerButton style={{ margin: "2rem auto" }}>Read more</BannerButton>
        </Link>
      </div>
    );
  }
}

// const ScaleCardBG = styled.div`
//   background: url(${props => props.img}) 50% / cover no-repeat;
// `;

/**************************************/
//          ServicesWrapper SC
/**************************************/
const ScaleCardWrapper = styled.div`
  /* html { */
  /* margin: 2rem 0rem; */
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  /* animation: bot-to-top 5s ease-out;
  @keyframes bot-to-top {
    0% {
      bottom: -40px;
    }
    50% {
      bottom: 40px;
    }
  } */

  /* } */
  #btns * {
    transition: all 0.3s ease-in-out;
  }
  #btns {
    /* max-width: 1200px; */
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-flow: row wrap;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .btn {
    position: relative;
    
    padding: 20px 20px 25px;
    width: 33.333%;
    height: 350px;
    background: #ffffff;
    /* background: #1491e1; */

    /* border-right: 3px solid #eee; */
    cursor: pointer;
  }

  /* Icon */
  .btn .icon {
    display: block;
    margin: 10px auto;
    width: 82px;
    height: 82px;
    transform-origin: center;
    transform: scale(0.9);
    /* color: #fff; */
  }
  .btn .icon svg {
    /* fill: blue;
    filter: blue; */
  }
  .btn .icon {
    /* fill: currentColor; */
    /* filter: invert(1); */
  }
  /* Text Styles */
  .btn h2 {
    margin-bottom: 1.3rem;
    text-align: center;
    color: #096EEC;

    /* color: #323232; */
    /* font-family: raleway; */
    font-size: 1em;
    line-height: 1em;
    font-weight: 500;
    text-transform: uppercase;
  }
  .btn p {    
    /* color: ${styles.colors.mainSubtitleClr}; */
    color: #595959;
    /* color: rgba(50, 50, 50, 0.8); */
    text-align: center;
    font-size: 1em;
    line-height: 1.6em;
    font-weight: 200;
    text-transform: none;
  }
  /* Hover Effects */
  .btn:hover {
    background: #1491e1;
    /* background: #fff; */
  }
  .btn:hover:after {
    border-left: 20px solid #ccc;
  }
  .btn:hover .icon {
    transform-origin: center;
    /* transform: scale(1); */
    filter: invert(1);
    /* color: #fff; */
  }
  .btn:hover h2 {
    /* color: ${styles.colors.mainBlueColor}; */
    color: #ffffff;
    /* color: #fff; */
  }
  .btn:hover p {
    /* color: ${styles.colors.mainSubtitleClr}; */
    color: #eeeeee;
  }
  
  /* Media Queries */
  @media (max-width: 700px) {
    .btn {
      display: block;
      width: 100%;
      height: 360px;
    }
    .btn p {
      font-size: 0.8em;
      line-height: 1.5em;
    }
  }
  @media (min-width: 701px) {
    .btn {
      /* 4 cards */
      /* width: 50%; */
      /* 3 cards*/
      width: 33.333%;
      height: 385px;
    }
    .btn p {
      font-size: 0.7em;
      line-height: 1.5em;
    }
  }
  @media (min-width: 1100px) {
    .btn {
      /* 4 cards */
      /* width: 25%; */
      /* height: 320px; */
      /* 3 cards */
      width: 33.333%;
      height: 330px;
    }
    /* Icon */
    .btn .icon {
      width: 80px;
      height: 80px;
      /* color: #fff; */
    }
    .btn p {
      font-size: 0.9em;
    }
  }
`;

/**************************************/
//          Graphql Constants
/**************************************/
const SERVICEQUERY = graphql`
  {
    servicequery: allContentfulServiceCard(
      filter: { title: { ne: "Team Coaching" } }
      sort: { fields: [index], order: ASC }
    ) {
      edges {
        node {
          index
          title
          subtitle
          shortDescription {
            shortDescription
          }
          iconTitle
        }
      }
    }
  }
`;

import React from "react";
import ColorCard from "./ColorCard";
import { Title, Section, BannerButton } from "../../utils/";
import { Link } from "gatsby";

export default function ColorCardList({ title, message }) {
  return (
    <div>
      <Section>
        <Title message={message} title={title} />
        {/* <div className="divider-line" /> */}
      </Section>
      <ColorCard />
      <Link to="/solutions/">
        <BannerButton style={{ margin: "4rem auto" }}>Read more</BannerButton>
      </Link>
    </div>
  );
}

/**
 * Source:
 *  https://codepen.io/maheshambure21/details/qZZrxy
 */

import React, { Component } from "react";
import styled from "styled-components";
import { styles } from "../utils/";
import { graphql, StaticQuery } from "gatsby";

export default class QuotesSlide extends Component {
  render() {
    return (
      <StaticQuery
        query={QuotingQUERY}
        render={data => {
          let homeData = data.quoteData.edges;
          return (
            <SliderWrapper>
              <h4 className="caption">{this.props.quoteTitle}</h4>
              <div className="slider">
                {homeData.map((item, index) => {
                  return (
                    <input
                      key={index}
                      type="radio"
                      name="slider"
                      title={"slide" + index + 1}
                      defaultChecked={index + 1 === 1 ? "checked" : ""}
                      className="slider__nav"
                    />
                  );
                })}
                <div className="slider__inner">
                  {homeData.map((item, index) => {
                    return (
                      <div className="slider__contents" key={index}>
                        {/* <i className="slider__image fa fa-codepen" /> */}
                        {/* <h4 className="slider__caption">
                          Why you should consider Blockchain
                        </h4> */}
                        <p className="slider__txt">
                          {item.node.description.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </SliderWrapper>
          );
        }}
      />
    );
  }
}

const QuotingQUERY = graphql`
  {
    quoteData: allContentfulQuoteSlider {
      edges {
        node {
          index
          description {
            description
          }
        }
      }
    }
  }
`;

/************************************/
/** RegisterWrapper Styled Component */
/************************************/
const SliderWrapper = styled.div`
  padding: 1rem auto;
  /* height: 100%; */
  /* background-color: #ddd; */
  color: #444;
  /* font-family: "Roboto", sans-serif; */
  font-size: 1rem;
  line-height: 1.5;

  .slider {
    height: 30vh;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  /* Special case: Overwrite Materialized default behavior  */
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: relative;
    opacity: 1;
    pointer-events: auto;
    /* background-color: #bbb; */
  }

  .slider__nav {
    width: 11px;
    height: 11px;
    margin: 2rem 12px;
    border-radius: 50%;
    z-index: 10;
    outline: 6px solid #ccc;
    box-shadow: 0 0 0 0 #ccc, 0 0 0 0 rgba(51, 51, 51, 0);
    outline-offset: -6px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .slider__nav:checked {
    -webkit-animation: check 0.4s linear forwards;
    animation: check 0.4s linear forwards;
  }
  .slider__nav:checked:nth-of-type(1) ~ .slider__inner {
    left: 0%;
  }
  .slider__nav:checked:nth-of-type(2) ~ .slider__inner {
    left: -100%;
  }
  .slider__nav:checked:nth-of-type(3) ~ .slider__inner {
    left: -200%;
  }
  .slider__nav:checked:nth-of-type(4) ~ .slider__inner {
    left: -300%;
  }
  /* delete the below two if u have only 4 items,
  increase the width from 400% to 600% for 6 items and so on ..*/
  .slider__nav:checked:nth-of-type(5) ~ .slider__inner {
    left: -400%;
  }
  .slider__nav:checked:nth-of-type(6) ~ .slider__inner {
    left: -500%;
  }

  .slider__inner {
    margin-top: 0rem;
    position: absolute;
    top: 0;
    left: 0;
    /* change the width to 400% if u have only 4 items */
    width: 600%;
    height: 100%;
    -webkit-transition: left 0.4s;
    transition: left 0.4s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
  .slider__contents {
    height: 100%;
    padding: 2rem;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .slider__image {
    font-size: 2.7rem;
    color: #2196f3;
  }

  h4,
  .slider__caption {
    font-size: 1.7rem;
    font-weight: 500;
    margin: 0.8rem 0 1rem;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
  }
  h4 {
    margin: 1rem 0 0.3rem 0;
    text-align: center;
    color: ${styles.colors.mainBlueColor};
  }
  .slider__txt {
    color: #595959;
    /* color: #999; */
    margin-bottom: 2rem;
    max-width: 80%;
    font-weight: 500;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-justify: left;
  }

  @keyframes check {
    50% {
      outline-color: ${styles.colors.mainBlueColor};
      box-shadow: 0 0 0 10px ${styles.colors.mainBlueColor},
        0 0 0 28px rgba(51, 51, 51, 0.2);
    }
    100% {
      outline-color: ${styles.colors.mainBlueColor};
      box-shadow: 0 0 0 0 ${styles.colors.mainBlueColor},
        0 0 0 0 rgba(51, 51, 51, 0);
    }
  }
  @media (max-width: 600px) {
    .slider__txt {
      font-size: 0.8rem;
    }
    h4 {
      font-size: 1.1rem;
      padding: 0rem 1rem;
    }
  }
`;

import React, { Component } from "react";
import styled from "styled-components";
// import { styles } from "../utils/";

/************************
 * Responsive Horizental Title/Steps bar.
 * change the state variable with the values needed to reflex on
 * the component.
 * TimerInterval: is used to periodically update the class state
 * //Source: https://codepen.io/cavellblood/pen/NNNbbg
 *************************/

export default class HStepsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
      Links: [
        {
          id: 1,
          title: "Backlog Breakdown",
          description: ""
        },
        {
          id: 2,
          title: "Plan & Prioritise",
          description: ""
        },
        {
          id: 3,
          title: "Implement",
          description: ""
        },
        {
          id: 4,
          title: "Review & Evaluate",
          description: ""
        },
        {
          id: 5,
          title: "Iterate & Repeat",
          description: ""
        }
      ]
    };
  }

  componentDidMount = () => {
    this.setTimer();
  };

  // componentWillUnmount only runs when the component is going away(unmounting) like leaving the page.

  componentWillUnmount = () => {
    this.clearTimer();
  };

  setTimer = () => {
    if (this.timerHandle) {
      return;
    }
    // Remember the timer handle
    this.timerHandle = setInterval(() => {
      // Here the activeIndex is based on the state's Links array length
      if (this.state.activeIndex <= this.state.Links.length) {
        this.setState({ activeIndex: this.state.activeIndex + 1 });
      } else {
        this.setState({ activeIndex: 1 });
      }
    }, 2500);
  };

  clearTimer = () => {
    // Is our timer running?
    if (this.timerHandle) {
      // Yes, clear it
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };

  render() {
    const HandleClassStatus = i => {
      let classNme;
      this.state.activeIndex === i
        ? (classNme = "c-progress-steps__step  current")
        : this.state.activeIndex > i
        ? (classNme = "c-progress-steps__step  done")
        : (classNme = "c-progress-steps__step");
      return classNme;
    };
    return (
      <TimeLineWrapper>
        <div className="container">
          <ol className="c-progress-steps">
            {this.state.Links.map((link, index) => (
              <li key={index} className={HandleClassStatus(index + 1)}>
                {link.title}
              </li>
            ))}
          </ol>
        </div>
      </TimeLineWrapper>
    );
  }
}
/************************************/
/** SlidesInfo Styled Component */
/************************************/
const TimeLineWrapper = styled.div`
  margin: 4.5rem auto;
  counter-reset: counter;

  .c-progress-steps {
    margin: 0;
    list-style-type: none;
    font-family: Lato;
  }
  .c-progress-steps li {
    position: relative;
    font-size: 14px;
    color: #7f8c8d;
    padding: 2px 0 2px 23px;
  }

  .c-progress-steps li.done {
    color: #2ecc71;
  }

  .c-progress-steps li.done:before {
    color: #fff;
    background-color: #2ecc71;
    border: 1px solid #2ecc71;
  }
  .c-progress-steps li.current {
    color: #3498db;
    font-weight: bold;
  }
  .c-progress-steps li.current:before {
    color: #fff;
    background-color: #3498db;
    border: 1px solid #3498db;
  }
  .c-progress-steps li:before {
    position: absolute;
    content: counter(counter);
    counter-increment: counter;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 1px solid #7f8c8d;
    background-color: #7f8c8d;
    border-radius: 100%;
    left: 0;
    text-align: center;
  }
  @media (max-width: 650px) {
    .c-progress-steps li:before {
      text-align: center;
    }
  }
  @media (min-width: 777px) {
    .c-progress-steps {
      display: table;
      list-style-type: none;
      margin: 20px auto;
      padding: 0;
      table-layout: fixed;
      width: 100%;
    }
    .c-progress-steps li {
      display: table-cell;
      text-align: center;
      padding: 0;
      padding-bottom: 10px;
      white-space: nowrap;
      position: relative;
      border-left-width: 0;
      border-bottom-width: 4px;
      border-bottom-style: solid;
      border-bottom-color: #7f8c8d;
    }
    .c-progress-steps li.done {
      border-bottom-color: #2ecc71;
    }
    .c-progress-steps li.current {
      color: #3498db;
      font-size: 16px;
      line-height: 14px;
      border-bottom-color: #3498db;
    }
    .c-progress-steps li.current:before {
      color: #fff;
      background-color: #3498db;
    }
    .c-progress-steps li:before {
      bottom: -14px;
      left: 50%;
      margin-left: -9px;
    }
  }
`;
